<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <input-select-tag
          v-if="temFiltroCedente"
          unico
          class="col-12 col-md-10 mb-1"
          label="CPF ou CNPJ do Cedente"
          :options="cedenteOptions"
          v-model="form.cpfCnpjCedente"
          removerLabelAdicionarRemover
        />
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="row">
            <input-date
              class="col-12 col-md-6 mb-1"
              :label="$t('AUDITORIA.DE')"
              v-model="form.DataVencimentoDe"
              @blur="calcularDiasVencimento"
            >
              <template #label>
                <div class="d-flex align-items-center">
                  <b-badge variant="info" class="mr-2">
                    {{ $t("TITULOS.DATA_VENCIMENTO") }}
                  </b-badge>
                  <span>De:</span>
                </div>
              </template>
            </input-date>
            <input-date
              class="col-12 col-md-6 mb-1"
              :label="$t('AUDITORIA.ATE')"
              v-model="form.DataVencimentoAte"
            >
              <template #label>
                <div class="d-flex align-items-center">
                  <span>Até:</span>
                </div>
              </template>
            </input-date>
          </div>
        </div>
        <input-text
          class="col-12 col-md-2"
          ref="CpfCnpjSacado"
          label="CPF ou CNPJ do Sacado"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          v-model="form.CpfCnpjSacado"
        />
        <input-select
          class="col-12 col-md-2"
          :options="listaStatus"
          :label="$t('SACADO.STATUS')"
          v-model="form.Status"
        />
        <input-text
          class="col-12 col-md-2"
          ref="Titulo"
          label="Título"
          v-model="form.Titulo"
        />

        <div class="col-12 col-md-1">
          <div class="mb-3">{{ $t("SACADO.VISUALIZAR_C99") }}</div>
          <InputCheckbox
            :text="$t(`TITULOS.VISUALIZAR`)"
            @onChange="(e) => (form.C99 = !form.C99)"
            :value="form.C99"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-md-8">
          <div class="row">
            <input-date
              :class="`col-12 col-md-${form.codigoUsuarioAgente ? 3 : 4} mb-1`"
              :label="$t('AUDITORIA.DE')"
              v-model="form.dataLiquidacaoDe"
              @blur="calcularDiasLiquidacao"
              :disabled="habilitarBaixados"
            >
              <template #label>
                <div class="d-flex align-items-center">
                  <b-badge variant="info" class="mr-2">
                    {{ $t("TITULOS.DATA_LIQUIDACAO") }}
                  </b-badge>
                  <span class="justify-content-end">De:</span>
                </div>
              </template>
            </input-date>
            <input-date
              :class="`col-12 col-md-${form.codigoUsuarioAgente ? 3 : 4} mb-1`"
              :label="$t('AUDITORIA.ATE')"
              v-model="form.dataLiquidacaoAte"
              :disabled="habilitarBaixados"
            >
              <template #label>
                <div class="d-flex align-items-center">
                  <span>Até:</span>
                </div>
              </template>
            </input-date>

            <input-select-tag
              unico
              removerLabelAdicionarRemover
              class="col-12 col-md-4"
              label="Rótulo"
              :options="rotulosOptions"
              v-model="form.rotuloCodigo"
            />
            <input-text
              v-if="form.codigoUsuarioAgente"
              class="col-12 col-md-2"
              ref="CodigoUsuarioAgente"
              label="Código Agente"
              v-model="form.codigoUsuarioAgente"
              :disabled="true"
            />
          </div>
        </div>
        <div
          class="col-12 col-md mt-5 d-flex align-items-center justify-content-end"
        >
          <span class="font-weight-bold" v-if="form.Status == 3"
            >Títulos baixados somente os últimos 180 dias</span
          >
          <b-button variant="secondary" @click="limparFiltro" class="ml-2">
            {{ $t("GERAL.LIMPAR") }}
          </b-button>
          <b-button
            @click="
              () => {
                if (!validarFiltro()) return;

                mostrarFiltro();
                listarHtmlExcel();
              }
            "
            variant="primary"
            class="ml-2"
            v-b-tooltip.hover
            title="Exportar relatório para PDF"
          >
            <div class="d-flex align-items-center">
              <i class="fas fa-file-excel mr-2"></i>
              <span>Exportar para excel</span>
            </div>
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="
              () => {
                if (!validarFiltro()) return;

                mostrarFiltro();
                listar();
              }
            "
          >
            {{ $t("GERAL.PESQUISAR") }}
          </b-button>
        </div>
      </div>
    </b-card>
    <b-alert
      variant="warning"
      :show="QuantidadeTotalRetonadoQprof != QuantidadeTotalQProf"
    >
      A consulta retornou
      <span class="font-weight-bold"
        >{{ QuantidadeTotalRetonadoQprof }} titulos</span
      >
      de um total de
      <span class="font-weight-bold">{{ QuantidadeTotalQProf }} titulos</span>.
      Para visualizar outros titulos, utilize outro intervalo de data.
    </b-alert>
    <b-card class="mb-4">
      <div class="d-flex justify-content-end">
        <div
          @click="obterBoletos()"
          :class="classBaixarArquivo"
          class="p-3 mt-0"
          :disabled="this.arquivosSelecionados.length > 1"
        >
          {{ $t("TITULOS.SEGUNDA_VIA_BOLETOS") }}
        </div>
      </div>
      <valorem-tabela
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        :por-pagina="tabela.porPagina"
        v-model="items"
        class="table-scroll"
        backend-paginado
        @paginar="listar"
        @sorting-changed="sortingChanged"
        :sem-ordenacao-local="true"
      >
        <template #cell(nomeSacado)="dados">
          <div
            class="nome-sacado"
            v-b-tooltip.hover
            :title="dados.item.nomeSacado"
          >
            {{ dados.item.nomeSacado }}
          </div>
        </template>
        <template #cell(situacaoProtesto)="dados">
          <div
            v-if="dados.item.situacaoProtesto"
            :class="`badge-table Baixado`"
          >
            {{ dados.item.situacaoProtesto }}
          </div>
        </template>
        <template #cell(situacaoRecompraC99)="dados">
          <div
            v-if="dados.item.situacaoRecompraC99"
            :class="`badge-table Aberto`"
          >
            Recompra
          </div>
        </template>
        <template style="width: 50px" v-slot:cell(status)="item">
          <div
            :class="`badge-table ${
              item.item.status == 'Em Atraso'
                ? 'Vencido'
                : item.item.status == 'Baixado'
                ? 'Baixado'
                : 'Aberto'
            }`"
          >
            {{ item.item.status }}
          </div>
        </template>
        <!-- <template style="width: 50px" v-slot:cell(status)="item">
          <div :class="`badge-table ${item.item.status}`">
            {{ $t(`STATUS.${item.item.status.toUpperCase()}`) }}
          </div>
        </template> -->
        <template style="width: 50px" v-slot:cell(arquivo)="item">
          <div
            v-if="item.item.flagBaixar && item.item.flagTemBoleto"
            class="flex-pos"
          >
            <!-- <InputCheckbox
              @onChange="() => selectItem(item.index)"
              :value="item.item.active ? true : false"
            /> -->
            <feather
              v-if="item.item.flagTemBoleto"
              type="file"
              class="feather-sm"
            ></feather>
          </div>
        </template>
        <!-- </b-table> -->
      </valorem-tabela>
    </b-card>
    <b-row class="operacoes-infos res-inputs">
      <input-text
        :value="valorTotal"
        class="sacado-text"
        :readonly="true"
        :label="$t('SACADO.VALOR_TOTAL')"
      />
      <input-text
        :value="valorTotalAtualizado"
        class="sacado-text"
        :readonly="true"
        :label="$t('SACADO.VALOR_TOTAL_ATUALIZADO')"
      />
      <div
        @click="obterBoletos()"
        :class="classBaixarArquivo"
        :disabled="this.arquivosSelecionados.length > 1"
      >
        {{ $t("TITULOS.SEGUNDA_VIA_BOLETOS") }}
      </div>
    </b-row>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  InputDate,
  InputText,
  InputSelect,
  InputSelectTag,
  InputCheckbox,
} from "@/components/inputs";
import { Excel } from "@/common/utils/excel.js";
import CedenteService from "@/common/services/cedente/cedente.service";
import RotuloBoletosService from "@/common/services/rotulo-boletos/rotulo-boletos.service";
import Toast from "vue-toastification";
import Vue from "vue";
import helpers from "@/common/utils/helpers";
import moment from "moment";
import UsuarioService from "@/common/services/usuario/usuario.service";
import Confirmacao from "@/components/confirmacao/Confirmacao";

const options = {};
Vue.use(Toast, options);

export default {
  name: "AcessoSacado",
  components: {
    InputText,
    InputCheckbox,
    InputSelect,
    InputDate,
    InputSelectTag,
  },
  data() {
    return {
      filtro: false,
      form: {
        C99: false,
        C99String: "Não",
        Status: 0,
        DataVenciementoDe: null,
        DataVencimentoAte: null,
        dataLiquidacaoDe: null,
        dataLiquidacaoAte: null,
        rotuloCodigo: null,
        cpfCnpjCedente: "",
        codigoUsuarioAgente: null,
      },
      rotulosOptions: [],
      cedenteOptions: [],
      simNaoOptions: ["Sim", "Não"],
      listaC99: [
        { value: true, text: "Sim" },
        { value: false, text: "Não" },
      ],
      listaStatus: [
        { value: 0, text: "A Vencer" }, // Aberto
        { value: 1, text: "Vencidos" }, // Aberto
        { value: 2, text: "Todos em aberto" },
        { value: 3, text: this.$t("STATUS.BAIXADO") },
      ],
      valorTotal: 0,
      valorTotalAtualizado: 0,
      tempoAcesso: "",
      QuantidadeTotalRetonadoQprof: 0,
      QuantidadeTotalQProf: 0,
      items: [],
      tabela: {
        // dados: [],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 10,
        colunas: [
          { key: "seletor", thClass: "width-50" },
          {
            key: "arquivo",
            label: "Arquivo",
            thClass: "item-table",
          },
          {
            key: "nomeSacado",
            label: this.$t("SACADO.SACADO"),
            sortable: true,
            thClass: "fornecedor",
          },
          {
            key: "titulo",
            label: this.$t("SACADO.TITULO"),
            sortable: true,
          },
          {
            key: "dataVencimento",
            label: this.$t("SACADO.DATA_VENCIMENTO"),
            formatter: (value) => {
              if (value) return helpers.formatarDataBr(value);
            },
            sortable: true,
            thClass: "texto-quebrado-data",
          },
          // {
          //   key: "flagVencido",
          //   label: this.$t("SACADO.STATUS"),
          //   sortable: true,
          // },
          {
            key: "status",
            label: this.$t("SACADO.STATUS"),
            sortable: true,
          },
          {
            key: "valor",
            label: this.$t("SACADO.VALOR"),
            formatter: (value) => {
              if (value) return helpers.formatarValor(value);
            },
            sortable: true,
          },
          {
            key: "valorAtualizado",
            label: this.$t("SACADO.VALOR_ATUALIZADO"),
            sortable: true,
            formatter: (a, b, c) => {
              if (c.status == "Baixado")
                return helpers.formatarValor(c.valorPago);

              if (a) return helpers.formatarValor(a || "0");
            },
            thClass: "texto-quebrado",
          },
          {
            key: "nomeFantasia",
            label: this.$t("SACADO.NOME_FANTASIA"),
            sortable: true,
            thClass: "fornecedor",
          },
          {
            key: "dataLiquidacao",
            label: this.$t("SACADO.DATA_LIQUIDACAO"),
            formatter: (value) => {
              if (value) return helpers.formatarDataBr(value);
            },
            sortable: true,
          },
          {
            key: "situacaoProtesto",
            label: this.$t("SACADO.SITUACAO_PROTESTO"),
            sortable: true,
          },
          {
            key: "situacaoRecompraC99",
            label: this.$t("SACADO.VISUALIZAR_C99"),
            sortable: true,
          },
          {
            key: "rotuloNome",
            label: this.$t("SACADO.ROTULO"),
            sortable: true,
          },
        ],
      },
      totalSelecionados: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: "Títulos" },
      { titulo: "Listar Títulos" },
    ]);

    this.getRotulos();

    var codAgente = localStorage.getItem("usuario_codigo_agente");
    if (codAgente) this.form.codigoUsuarioAgente = codAgente;

    if (this.temFiltroCedente) {
      this.getUsuarios();
    }

    if (this.$route.query.filtroStatus) {
      this.form.Status = this.$route.query.filtroStatus;
      this.listar();
    }

    this.totalSelecionados = this.arquivosSelecionados.length;
  },
  computed: {
    arquivosSelecionados: function () {
      return this.itens
        .filter((el) => {
          return el.selecionado && el.flagBaixar && el.flagTemBoleto;
        })
        .map((el) => {
          return {
            codigoEmpresa: el.codigoEmpresa,
            codigoFilial: el.codigoFilial,
            sequencialTitulo: el.sequencialTitulo,
            titulo: el.titulo,
          };
        });
    },
    itens() {
      return this.items;
    },
    classBaixarArquivo() {
      return this.arquivosSelecionados.length <= 0
        ? "btn-operacoes orange btn-desativado"
        : "btn-operacoes orange";
    },
    habilitarBaixados() {
      return this.form.Status != 3; // 3 é o valor do filtro baixados
    },
    temFiltroCedente() {
      console.log(helpers.isAdmin());
      return helpers.validarAcesso("FiltroTodosCedentesProgramacao").visualizar;
    },
    filtroCedenteIsEmpty() {
      return !!this.form?.cpfCnpjCedente;
    },
  },
  methods: {
    getRotulos() {
      this.$store.dispatch(START_LOADING);

      const params = {
        TamanhoDaPagina: 99999,
        NumeroDaPagina: 0,
      };

      RotuloBoletosService.listar(params)
        .then((result) => {
          this.rotulosOptions = result.data.data.itens.map((n) => ({
            value: n.codigo,
            text: n.nome,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    calcularDiasVencimento() {
      this.form.DataVencimentoAte = this.calcularDias(
        this.form.DataVencimentoDe
      );
    },
    calcularDiasLiquidacao() {
      this.form.dataLiquidacaoAte = this.calcularDias(
        this.form.dataLiquidacaoDe
      );
    },
    calcularDias(dataString) {
      var data = moment(dataString);
      return data.add("30", "days").format("YYYY-MM-DD");
    },
    limparFiltro() {
      this.form = {
        C99: false,
        cpfCnpjCedente: "",
      };

      this.items = [];
      this.QuantidadeTotalRetonadoQprof = 0;
      this.QuantidadeTotalQProf = 0;
    },
    mostrarFiltro() {
      this.filtro = !this.filtro;
    },
    sortingChanged(context) {
      this.items = [];
      this.form.CampoOrdenacao = helpers.primeiraLetraMaiuscula(context.sortBy);
      this.form.Ascendente = !context.sortDesc;

      this.listar();
    },
    downloadPDF(pdf, _fileName) {
      let type = this.arquivosSelecionados.length > 1 ? "zip" : "pdf";
      const linkSource = `data:application/${type};base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = _fileName;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    validarFiltro() {
      let arValidation = [];

      if (this.temFiltroCedente && !this.filtroCedenteIsEmpty) {
        Vue.$toast.error("É necessário selecionar um cedente no filtro.");
        return false;
      }

      arValidation.push(this.$refs.CpfCnpjSacado.valid());

      if (this.form.DataVencimentoAte || this.form.DataVencimentoDe) {
        let dataInicial = moment(this.form.DataVencimentoDe);
        let dataFinal = moment(this.form.DataVencimentoAte);

        if (dataFinal < dataInicial) {
          Vue.$toast.error("Data final não pode ser maior que a inicial");
          return false;
        }
      }

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    setPage(e) {
      this.listar(e, undefined);
      this.currentPage = e;
    },
    setPorPagina(e) {
      this.listar(undefined, e);
      this.perPage = e;
    },
    obterBoletos() {
      this.$store.dispatch(START_LOADING);
      let qtdeTitulos = this.arquivosSelecionados.length;
      if (qtdeTitulos > 20) {
        Confirmacao.Ok(
          this,
          this.$t("GERAL.SUCCESSO"),
          this.$t("TITULOS.LIMITE_TITULOS_SEGUNDA_VIA")
        ).then(() => {
          this.$store.dispatch(STOP_LOADING);
        });
      } else {
        CedenteService.obterBoletos({
          listaBoletosRequest: this.arquivosSelecionados,
          cnpjSacado: this.form.CpfCnpjSacado,
        })
          .then((result) => {
            let itens = result.data.data;

            this.downloadPDF(itens.arquivoBoleto, itens.arquivoNome);
          })
          .catch(() => {})
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    listar(numeroPagina = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);

      CedenteService.listarBoletos({
        ...this.form,
        NumeroDaPagina: numeroPagina,
        TamanhoDaPagina: porPagina,
      })
        .then((result) => {
          let itens = result.data.data.itens;
          let valorTotal = 0;
          let valorTotalAtualizado = 0;

          // console.log(itens);
          for (let i = 0; i < itens.length; i++) {
            valorTotal += itens[i].valor;
            valorTotalAtualizado += itens[i].valorAtualizado;
          }

          this.QuantidadeTotalRetonadoQprof =
            result.data.data.quantidadeTotalRetonadoQprof;
          this.QuantidadeTotalQProf = result.data.data.quantidadeTotalQProf;

          this.valorTotal = helpers.formatarValor(valorTotal);
          this.valorTotalAtualizado =
            helpers.formatarValor(valorTotalAtualizado);

          this.tabela.paginaAtual = numeroPagina;
          this.tabela.porPagina = porPagina;
          this.tabela.quantidadeItens =
            result.data.data.paginacao.totalDeElementos;
          this.items = itens;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    listarHtmlExcel() {
      this.$store.dispatch(START_LOADING);

      CedenteService.listarBoletosHtmlExcel(this.form)
        .then((res) => {
          let htmlString = res.data.data.html;
          let doc = new DOMParser().parseFromString(htmlString, "text/html");
          const ex = new Excel();

          for (let sheet of doc.getElementsByClassName("sheet")) {
            ex.adicionarSheet(sheet, sheet.getAttribute("nome-sheet"));
          }

          ex.baixarExcel("Relatório.xlsx");
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getUsuarios() {
      this.$store.dispatch(START_LOADING);

      const params = {
        TamanhoDaPagina: 99999,
        NumeroDaPagina: 0,
      };
      UsuarioService.listar(params)
        .then((result) => {
          this.cedenteOptions = result.data.data.itens.map((n) => ({
            value: n.cnpj,
            text: n.nomeEmpresa,
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    onChangeCedente(value) {
      this.form.cpfCnpjCedente = value;
    },
  },
  watch: {
    "form.C99String": function () {
      this.form.C99 = false;
      if (this.form.C99String == "Sim") {
        this.form.C99 = true;
      }
    },
  },
};
</script>
<style>
.texto-quebrado div {
  width: 120px;
  white-space: pre-wrap;
  text-align: center;
}
.texto-quebrado-data div {
  width: 95px;
  white-space: pre-wrap;
  text-align: center;
}
.b-table th {
  vertical-align: middle !important;
}
.nome-sacado {
  width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-50 {
  min-width: 50px !important;
}
</style>